//export const DEFAULT_MASTER_API_URL = "https://api.staging.audience.direct";
//export const DEFAULT_PROJECT_API_URL = "https://api.staging.audience.direct";
//export const DEFAULT_STORAGE_URL = "https://static.staging.sales-drive.app/storage/nespresso";

export const DEFAULT_MASTER_API_URL = "https://api.staging.sales-drive.app";
export const DEFAULT_PROJECT_API_URL = "https://api.staging.sales-drive.app";
export const DEFAULT_STORAGE_URL = "https://static.staging.sales-drive.app/storage/nespresso";

export enum LocalStorageKey {
  AUDIENCES_META = "AUDIENCES_META",
  COMPANIES_META = "COMPANIES_META",
  CONTENTS_META = "CONTENTS_META",
  ASSET_FOLDERS_META = "ASSET_FOLDERS_META",
  SLIDES_META = "SLIDES_META",
  DATA_PROVIDERS_META = "DATA_PROVIDERS_META",
  AUDIENCE_SESSIONS = "AUDIENCE_SESSIONS",
  AUDIENCE_SESSION_EVENTS = "AUDIENCE_SESSION_EVENTS",
  ERROR_REPORTS = "ERROR_REPORTS",
  DOMAIN_INFO = "DOMAIN_INFO",
  OFFLINE_AVAILABILITY = "OFFLINE_AVAILABILITY",
  USER_SESSION = "USER_SESSION",
  PWA_INSTALLED = "PWA_INSTALLED",
  AUDIENCE_LIST_COLLAPSED = "AUDIENCE_LIST_COLLAPSED",
  GROUP_BY_COMPANY = "GROUP_BY_COMPANY",
  DEEPLINK_PARAMS = "DEEPLINK_PARAMS",
  INTRODUCTION_BOX_SHOWN = "INTRODUCTION_BOX_SHOWN",
  HINTS = "HINTS",
  VERSION = "VERSION",
  GAMIFICATION_LEVEL_INDEX = "GAMIFICATION_LEVEL_INDEX",
  GAMIFICATION_STATUS_INDEX = "GAMIFICATION_STATUS_INDEX",
  GAMIFICATION_INTRODUCTION_BOX_SHOWN = "GAMIFICATION_INTRODUCTION_BOX_SHOWN",
  ROAD_MODE = "ROAD_MODE",
  LIKED_CONTENT = "PWA_LIKED_CONTENT"
}

export enum SessionStorageKey {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  TOKEN_EXPIRATION = "TOKEN_EXPIRATION",
  REFRESH_TOKEN = "REFRESH_TOKEN"
}

export enum DtoType {
  BODY = "BODY",
  META = "META",
  OUTLINE = "OUTLINE",
  CONFIG = "CONFIG",
}
