import React, {useContext, useEffect, useState} from "react";
import contentListController from "../_controller/ContentListController";
import styles from './ContentOverview.module.scss';
import ContentOverviewItem from "./components/ContentOverviewItem";
import {Alert, Button, Spinner} from "react-bootstrap";
import classNames from "classnames";
import ContentSearch from "./components/ContentSearch";
import ContentModel from "../_model/ContentModel";
import audienceListController from "../../audience/_controller/AudienceListController";
import OutlookAppModel from "../../_model/OutlookAppModel";
import {Trans, useTranslation} from "react-i18next";
import {IEntityAudienceShareDto} from "../../entity/_model/entity.dto";
import UserModel from "../../user/_model/UserModel";
import AudienceModel from "../../audience/_model/AudienceModel";
import contentController from "../_controller/ContentController";
import CreateAudienceModal from "../../audience/_view/CreateAudienceModal";
import {AuthContext} from "../../__lib/providers/AuthProvider";
import SelectAudienceModal from '../../audience/_view/SelectAudienceModal';
import {getMlValue} from '../../__lib/language_manager/LanguageManager';

const contentListModel = OutlookAppModel.getInstance().content;
const audienceListModel = OutlookAppModel.getInstance().audiences;

const initialShareDto: IEntityAudienceShareDto = {
    audienceID: '',
    doesExpire: false,
    expirationDate: new Date(Date.now() + 12096e5),
    notifyExpiration: false,
    notifyViewed: true,
    userID: UserModel.getInstance().ID,
}

export default function ContentOverviewPage() {
    const {signOut} = useContext(AuthContext);
    const {t} = useTranslation();

    const [content, setContent] = useState<ContentModel[]>(contentListModel.getAll);
    const [isLoading, setIsLoading] = useState(true);

    const [showEnterAudienceError, setShowEnterAudienceError] = useState(false);
    const [showSelectAudienceModal, setShowSelectAudienceModal] = useState(false);

    const [showSharedLinkMsg, setShowSharedLinkMsg] = useState(false);
    const [sharedLinkRecipient, setSharedLinkRecipient] = useState<string|null>('');

    const [emailRecipients, setEmailRecipients] = useState<Office.EmailAddressDetails[]>([]);
    const [showNewAudienceModal, setShowNewAudienceModal] = useState(false);
    const [clickedContent, setClickedContent] = useState<ContentModel|null>(null);

    const [shareDto, setShareDto] = useState<IEntityAudienceShareDto>(initialShareDto);
    const [isCreatingShare, setIsCreatingShare] = useState(false);

    useEffect(() => {
      init();
    }, []);

    const init = async() => {
      setIsLoading(true);
      await contentListController.fetchAllEntities();
      setContent(contentListModel.getAll);
      setIsLoading(false);

      await audienceListController.fetchAllEntitiesMeta();
      await audienceListController.fetchAllEntities();
    }

    const doShare = async (content: ContentModel, audience: AudienceModel) => {
      setIsCreatingShare(true);
      shareDto.audienceID = audience.ID;

      const shareRes = await contentController.shareContentWithAudience(content, shareDto);
      if (!shareRes.hasSucceeded) return;

      const user = UserModel.getInstance();
      let portalLink = `${OutlookAppModel.getInstance().storageUrl}/portal/index.html?apiUrl=${user.project.apiUrl}&portalToken=${audience.portalToken}`;

      if (shareRes.result && shareRes.result.shortLinkID) {
        portalLink = `${user.project.apiUrl}/sl/${shareRes.result.shortLinkID}`;
      }

      setShowSharedLinkMsg(true);
      setSharedLinkRecipient(audience.displayName || audience.email);

      if (Office.context.mailbox?.item?.body) {
        Office.context.mailbox.item.body.setSelectedDataAsync('<a href="' + portalLink + '">' + portalLink + '</a>', {coercionType: Office.CoercionType.Html});
      }

      setIsCreatingShare(false);
      setTimeout(() => {
        setShowSharedLinkMsg(false);
        setSharedLinkRecipient(null);
      }, 5000);
    }

    const onClickItem = (content: ContentModel) => {
      if (!Office.context.mailbox?.item?.to) {
        setShowEnterAudienceError(true);
        setTimeout(() => { setShowEnterAudienceError(false); }, 10000);
        return;
      }

      Office.context.mailbox.item.to.getAsync((result) => {
        if (!result.value || result.value.length === 0) {
          setShowEnterAudienceError(true);
          setTimeout(() => { setShowEnterAudienceError(false); }, 10000);
        }
        else if (result.value.length === 1) {
          createAudienceOrShare(result.value[0], content);
        }
        else if (result.value && result.value.length > 1) {
          setShowSelectAudienceModal(true);
          setEmailRecipients(result.value);
          setClickedContent(content);
        }
      });
    }

    const createAudienceOrShare = (recipient: Office.EmailAddressDetails, content: ContentModel = clickedContent) => {
      setShowSelectAudienceModal(false);

      const existingAudience = audienceListModel.getAll.find(a => a.email.toLowerCase() === recipient.emailAddress.toLowerCase());
      if (existingAudience) {
        doShare(content, existingAudience);
        return;
      }

      // show new audience modal
      setEmailRecipients([recipient]);
      setClickedContent(content);
      setShowNewAudienceModal(true);
    }

    const onAudienceCreated = (newAudience: AudienceModel) => {
      setShowNewAudienceModal(false);
      doShare(clickedContent, newAudience);
    }

    if (isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner />
        </div>
      );
    }

    return (
        <div className={styles['content-overview']}>
          <div className="d-flex align-items-center py-3">
            <ContentSearch
              content={content}
              onFilter={(filtered) => setContent(filtered)}
            />
          </div>

          <div className={classNames(styles.items, styles.normal)}>
            {content.map((item) => (
              <ContentOverviewItem
                key={item.ID}
                parentReference="overview"
                content={item}
                isCreatingShare={isCreatingShare}
                onClick={onClickItem}
              />
            ))}
          </div>

          <div className={styles['content-overview__log-out']}>
            {/*<span>Click here to log out of Salesdrive</span>*/}
            <Button onClick={signOut}>Sign out</Button>
          </div>

          {showSharedLinkMsg && (
            <Alert variant="primary" className={styles['shared-alert']}>
              <Trans i18nKey="ContentSharedWith" values={{ user: sharedLinkRecipient }} />
            </Alert>
          )}

          {showEnterAudienceError && (
            <Alert variant="danger" className={styles['audience-error']}>
              <Trans i18nKey="EnterAudienceError" />
            </Alert>
          )}

          {showNewAudienceModal && (
            <CreateAudienceModal
              recipient={emailRecipients[0]}
              onHide={(newAudience) => {
                if (newAudience) {
                  onAudienceCreated(newAudience);
                } else {
                  setEmailRecipients(null);
                  setClickedContent(null);
                  setShowNewAudienceModal(false);
                }
              }}
            />
          )}

          {showSelectAudienceModal && (
            <SelectAudienceModal
              recipients={emailRecipients}
              sharedContent={clickedContent?.name ? getMlValue(clickedContent.name) : t("YourContent")}
              onClickRecipient={(recipient) => createAudienceOrShare(recipient)}
              onHide={() => setShowSelectAudienceModal(false)}
            />
          )}
        </div>
    );
}
